<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="zhicheng-tips">
        <div class="zhicheng-tips-title">
          <span>选择职称：</span>{{ xuanzezhicheng }}
        </div>
        <div class="zhicheng-tips-title" v-if="zhichengxiangqing.pkmark != ''">
          <span>学术论文：</span>{{ zhichengxiangqing.pkmark }}篇
        </div>
        <div class="zhicheng-tips-title" v-if="zhichengxiangqing.hxmark != ''">
          <span>科普文章：</span>{{ zhichengxiangqing.hxmark }}篇
        </div>
        <div class="zhicheng-tips-con">
          <div
            class="zhicheng-tips-con-title"
            v-bind="zhichengxiangqing.lysource != ''"
          >
            职称要求<a :href="zhichengxiangqing.lysource" target="_blank"
              >查看来源</a
            >
          </div>
          <div
            class="zhicheng-tips-con-nr"
            v-if="zhichengxiangqing.yaoqiu != ''"
          >
            <el-input
              v-model="zhichengxiangqing.yaoqiu"
              type="textarea"
              autosize
              disabled
            />
          </div>
        </div>
        <div class="zhicheng-tips-con" v-if="zhichengxiangqing.remark != ''">
          <div class="zhicheng-tips-con-title">职称说明</div>
          <div class="zhicheng-tips-con-nr">
            <el-input
              v-model="zhichengxiangqing.remark"
              type="textarea"
              autosize
              disabled
            />
          </div>
        </div>
        <div class="zhicheng-btns">
          <el-button type="primary" @click="clickcxxz">重新选择</el-button>
          <el-button type="success" @click="clicktougao">我要投稿</el-button>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, getCurrentInstance, ref } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();

// 获取链接参数
let zcid = router.currentRoute.value.query.id;
let zcname = decodeURIComponent(router.currentRoute.value.query.qikan);
let qikanlist = router.currentRoute.value.query.qikanlist
// console.log(zcname)
// 选择的职称
let xuanzezhicheng = ref('')
// 职称详情
let zhichengxiangqing = reactive({})


// 获取详情
const getxq=()=>{
  xuanzezhicheng.value=zcname
  get("/journalTitle/GetDet/"+zcid, {
    id: zcid,
  }).then((res) => {
    Object.assign(zhichengxiangqing,res.data)
  });
}
getxq()

// 返回
const clickcxxz=()=>{
  router.push({
    path: "/xzzc",
    query: {},
  });
}

// 投稿
const clicktougao=()=>{
  router.push({
    path: "/xkzs/sub",
    query: {
      // id
      id: zcid,
      // 名称用于显示
      qikan: encodeURIComponent(zcname),
      // 名称用于提交接口
      qikanlist:qikanlist,
    },
  });
}


</script>

<style scoped>
.main {
  position: relative;
  background: #fbfcff;
}
.content {
  width: 1200px;
  height: auto;
  padding: 30px 0;
  margin: auto;
}

.zhicheng-tips{
  background: #F5F7FA;
  padding: 20px 20px 10px 20px;
  font-size: 14px;
  margin-bottom: 20px;
  color: #333;
}
.zhicheng-tips-title{
  padding-bottom: 10px;
}
.zhicheng-tips-title > span{
  font-weight: bold;
}
.zhicheng-tips-con{
  margin-bottom: 10px;
}
.zhicheng-tips-con-title{
  font-weight: bold;
  padding-bottom: 5px;
}
.zhicheng-tips-con-title a{
  margin-left: 10px;
  font-weight: 500;
}
.zhicheng-tips-con-title a:link,.zhicheng-tips-con-title a:visited{
  text-decoration: none;
  color: #409EFF;
}
.zhicheng-tips-con-title a:hover{
  text-decoration: underline;
}

</style>
